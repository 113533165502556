<template>
    <div class="common-wrapper">
        <!-- 字典 -->
        <dict-list :list="dicts" ref="dictListRef" @updateList="getDictList" @changeDetail="getDictDetail" />
        <div class="content">
            <base-info :baseInfo="baseData" @updateBaseInfo="updateBaseInfo" />
            <BasicCard title="码值列表">
                <template #right>
                    <a-button type="primary" @click="handleAdd">添加</a-button>
                </template>
                <basic-table ref="tableRef2" v-bind="tableConfig2" @page-change="pageChange">
                    <template #slotStatus="{ row }">
                        <span>{{ row.status == 0 ? '正常' : '失效' }}</span>
                    </template>
                    <template #slotDefault="{ row }">
                        <span>{{ row.isDefault == 0 ? '是' : '否' }}</span>
                    </template>
                </basic-table>
            </BasicCard>
            <a-modal v-model:visible="visible" :title="`${isEditing ? '修改' : '添加'}码值`" @ok="ok" @cancel="cancel"
                :confirm-loading="loading">
                <a-form ref="formRef2" :model="formState2" :rules="rulesRef2" :label-col="{ span: 4 }">
                    <a-form-item name="value" label="字典值码：">
                        <a-input v-model:value="formState2.value" :disabled="formState2.isDefault === 0"></a-input>
                    </a-form-item>
                    <a-form-item name="label" label="值描述：">
                        <a-textarea v-model:value="formState2.label"></a-textarea>
                    </a-form-item>
                    <a-form-item name="status" label="状态：">
                        <a-switch v-model:checked="formState2.status" :unCheckedValue="1" :checkedValue="0"
                            :disabled="formState2.isDefault === 0" />
                    </a-form-item>
                </a-form>
            </a-modal>
        </div>

    </div>
</template>

<script setup>
// defineOptions({
//   name: 'DictionaryManage'
// })
import { apiDict } from '@/api/dict.js';
import { onMounted, ref, reactive, nextTick } from 'vue';
import BaseInfo from './components/baseInfo.vue'
import dictList from './components/dictList.vue'
import Sortable from 'sortablejs';
import { BasicTable } from '@/components/basic/table'
import { message, Modal } from 'ant-design-vue';
import { useUserStore } from "@/store/modules/user";
const userStore = useUserStore();

const dicts = ref([])

const baseData = reactive({
    dictId: null,
    type: 1,
    code: '',
    name: '',
    description: ''
})
const dictListRef = ref()

const tableConfig2 = reactive({
    showIndex: false,
    showSelect: false,
    pagination: false,
    isPageBottom: true,
    scroll:{y:'calc(100vh - 400px)'},
    columns: [
        {
            title: "字典值码",
            type: "text",
            key: "value",
            width: '20%'
        },
        {
            title: "值描述",
            type: "text",
            key: "label",
            width: '20%'

        },
        {
            title: "状态",
            type: "customize",
            slotName: "slotStatus",
            width: '20%'
        },
        {
            title: "默认",
            type: "customize",
            slotName: "slotDefault",
        },
    ],
    action: {
        title: "操作",
        width: 100,
        fixed: "right",
        items: [
            {
                icon: "DragOutlined",
                iconTip:'拖拽',
                type: "icon",
            },
            {
                icon: "FormOutlined",
                iconTip:'编辑',
                type: "icon",
                onClick: (row) => {
                    handleEdit(row);
                },
            },
            {
                icon: "DeleteOutlined",
                iconTip:'删除',
                type: "icon",
                style: { color: '#0256FF' },
                visibleFn: (row) => row.isDefault === 1,
                onClick: (row) => {
                    Modal.confirm({
                        title: '提醒',
                        content: '请确认是否删除？',
                        onOk() {
                            return new Promise((resolve) => {
                                apiDict.delete(row.id).then(res => {
                                    if (res.errorCode === '200') {
                                        message.success('删除成功');
                                        getDictDetail()
                                    }
                                }).finally(() => {
                                    resolve()
                                })
                            })
                        }
                    });
                },
            },
        ],
    },
})
const tableRef2 = ref();

const isEditing = ref(false)
const visible = ref(false)
const loading = ref(false)
const formState2 = reactive({
    id: null,
    value: null,
    label: null,
    status: 0
})
const formRef2 = ref()
const rulesRef2 = reactive({
    value: [{ required: true, message: '请输入字典值码' }],
    label: [{ required: true, message: '请输入值描述' }]
});

onMounted(() => {
    initData()
})

const initData = async (keywords) => {
    let result = await getDictList(keywords)
    getDictDetail(result[0].dictList[0]?.dictId)
}

const getDictList = async keywords => {
    let { result } = await apiDict.list(keywords)
    dicts.value = result
    return result

}
const getDictDetail = id => {
    id = id || baseData.dictId
    if (id) {
        apiDict.detail(id).then(({ result }) => {
            for (const key in baseData) {
                baseData[key] = result[key]
            }
            tableRef2.value.dataSourceRef = result.dictDataList

            setTimeout(() => {
                initSort()
            }, 200)
        })
    }
}

const updateBaseInfo = (e) => {
    baseData.name = e.name
    baseData.description = e.description
    getDictList(dictListRef.value.keywords)
}

const pageChange = (pageIndex) => {
    tableRef2.value.paginationProps.current = pageIndex
    getDictDetail()
}

const initSort = () => {
    let el = document.querySelector('.ant-table-tbody')
    new Sortable(el, {
        group: 'sort',
        animation: 150,
        sort: true,
        handle: '.anticon-drag',
        onEnd: function (evt) {
            let dictDataId = evt.item.dataset.rowKey
            let nextDictDataId = evt.item.nextElementSibling?.dataset.rowKey
            apiDict.sort({ dictDataId, nextDictDataId }).then(() => {
                getDictDetail()
            })

            // var itemEl = evt.item;  // dragged HTMLElement
            // evt.to;    // target list
            // evt.from;  // previous list
            // evt.oldIndex;  // element's old index within old parent
            // evt.newIndex;  // element's new index within new parent
            // evt.oldDraggableIndex; // element's old index within old parent, only counting draggable elements
            // evt.newDraggableIndex; // element's new index within new parent, only counting draggable elements
            // evt.clone // the clone element
            // evt.pullMode;  // when item is in another sortable: `"clone"` if cloning, `true` if moving
        },
    })
}

const handleAdd = () => {
    isEditing.value = false
    visible.value = true;
}

const handleEdit = row => {
    isEditing.value = true
    visible.value = true;
    nextTick(() => {
        for (const key in formState2) {
            formState2[key] = row[key]
            formState2.isDefault = row.isDefault
        }
    })
}

const ok = () => {
    formRef2.value.validateFields().then(() => {
        loading.value = true
        let m = {
            value: formState2.value,
            label: formState2.label,
            status: formState2.status,
            tenantId:userStore.userInfo?.tenantId
        }
        isEditing.value ? m.id = formState2.id : m.dictId = baseData.dictId
        let func = isEditing.value ? 'edit' : 'add'
        apiDict[func](m).then(() => {
            message.success(`${isEditing.value ? '修改' : '添加'}成功`)
            visible.value = false
            formRef2.value.resetFields();
            getDictDetail()
        }).finally(() => {
            loading.value = false
        })

    });

}
const cancel = () => {
    // visible.value = false;
    formRef2.value.resetFields();
    delete formState2.isDefault
}


</script>
<style lang='less' scoped>
.common-wrapper {
    display: flex;
    padding: 0;
    height: 100%;
    background-color: transparent;


    .content {
        flex: 1;
        padding: 0 20px;
        background-color: #fff;
    }
}
</style>
