<template>
    <BasicCard title="基础信息">
        <template #right>
            <a-button type="primary" @click="handleEdit">编辑</a-button>
        </template>
        <a-descriptions class="descriptions">
            <a-descriptions-item label="字典归属">{{ baseInfo.type == 1 ? '系统字典' : '租户字典' }}</a-descriptions-item>
            <a-descriptions-item label="字典键码">{{ baseInfo.code }}</a-descriptions-item>
            <a-descriptions-item label="字典名称">{{ baseInfo.name }}</a-descriptions-item>
            <a-descriptions-item label="字典描述" :span="3">{{ baseInfo.description }}</a-descriptions-item>
        </a-descriptions>
    </BasicCard>
    <a-modal v-model:visible="visible" title="编辑基础信息" @ok="ok" @cancel="cancel" :confirm-loading="loading">
        <a-form ref="formRef" :model="formState" :rules="rulesRef" :label-col="{ span: 4 }">
            <a-form-item name="type" label="字典归属：">
                <a-select v-model:value="formState.type" disabled>
                    <a-select-option :value="1">系统字典</a-select-option>
                    <a-select-option :value="2">租户字典</a-select-option>
                </a-select>
            </a-form-item>
            <a-form-item name="code" label="字典键码：">
                <a-input v-model:value="formState.code" disabled></a-input>
            </a-form-item>
            <a-form-item name="name" label="字典名称：">
                <a-input v-model:value="formState.name"></a-input>
            </a-form-item>
            <a-form-item name="description" label="字典描述：">
                <a-textarea v-model:value="formState.description"></a-textarea>
            </a-form-item>
        </a-form>
    </a-modal>
</template>

<script setup>
import { ref, reactive, defineProps, defineEmits } from 'vue';
import { apiDict } from '@/api/dict.js';
import { message } from 'ant-design-vue';

const props = defineProps({
    baseInfo: Object
})

const emit = defineEmits(['updateBaseInfo'])

const visible = ref(false)
const loading = ref(false)
const formState = reactive({
    dictId: '',
    type: null,
    code: '',
    name: '',
    description: '',
})
const formRef = ref()
const rulesRef = reactive({
    name: [{ required: true, message: '请输入字典名称' }],
    description: [{ required: true, message: '请输入字典描述' }],
});


const handleEdit = () => {
    visible.value = true
    for (const key in formState) {
        formState[key] = props.baseInfo[key]
    }
}
const ok = () => {
    formRef.value.validateFields().then(() => {
        loading.value = true
        let m = {
            id: formState.dictId,
            name: formState.name,
            description: formState.description,
        }
        apiDict.editBase(m).then(() => {
            message.success('修改成功')
            visible.value = false
            emit('updateBaseInfo', m)
        }).finally(() => {
            loading.value = false
        })

    });
}
const cancel = () => {
    formRef.value.resetFields();
    visible.value = false
}
</script>
<style lang='less' scoped>
.descriptions {
    padding: 17px 20px 0 20px;
    border: 1px solid #E6E8EB;
    border-radius: 4px;
}
</style>