<template>
    <div class="aside">
        <a-input-search v-model:value="keywords" placeholder="请输入" @search="search" />
        <a-menu v-model:selectedKeys="selectedKeys" mode="inline" @select="select">
            <a-menu-item-group v-for="(item, idx) in list" :key="idx">
                <template #title>{{ item.dictType }}</template>
                <a-menu-item v-for="sub in item.dictList" :key="sub.dictId">
                    <a-tooltip placement="topLeft" :title="`${sub.dictName} - ${sub.dictCode}`">
                        {{ sub.dictName }} - {{ sub.dictCode }}
                    </a-tooltip>
                </a-menu-item>
            </a-menu-item-group>
        </a-menu>
    </div>
</template>

<script setup>
import { ref, defineProps, defineEmits, watch, defineExpose } from 'vue';

const props = defineProps({
    list: Array
})
const emit = defineEmits(['updateList', 'changeDetail'])

const selectedKeys = ref([]);
const keywords = ref()

watch(() => props.list, (list) => {
    if (!selectedKeys.value.length) {
        selectedKeys.value = [list[0].dictList[0]?.dictId]
    }
})
const select = e => {
    emit('changeDetail', e.key)
}
const search = e => {
    emit('updateList', e)
}
defineExpose({ keywords })

</script>
<style lang='less' scoped>
.aside {
    display: inline-block;
    margin-right: 8px;
    padding: 12px 8px;
    width: 240px;
    box-sizing: border-box;
    background-color: #fff;
    overflow-y: auto;
}

:deep .ant-menu {
    &-inline {
        border: none;
    }

    &-item {
        margin: 0 !important;
        padding: 0 !important;
        color: #4E5969;
    }

    &-item-group-title {
        padding: 0 12px;
        line-height: 48px;
        font-size: 16px;
        color: rgba(0, 0, 0, 0.8);
        font-weight: 600;
    }

    &-title-content {
        margin: 0 16px;
        height: 40px;
        line-height: 40px;
        font-size: 14px;
    }

    &-item-selected {
        color: #0256FF;
    }

}

:deep .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    border-radius: 2px;
    background-color: #F2F3F5;
}

:deep .ant-menu-inline .ant-menu-item-selected::after {
    display: none !important;
}

.ant-descriptions {
    padding: 20px 20px 4px;
    border-radius: 4px;
    border: 1px solid #E6E8EB;

    :deep &-item-label {
        color: #666;
    }

    :deep &-item-content {
        color: #333;
    }

}
</style>