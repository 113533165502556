<template>
  <div ref="wrapRef" class="basic-table-wrapper">
    <!--table上面的显示： 搜索，新增等-->
    <div ref="tableHeaderRef" class="basic-table-header">
      <div class="left-operate">
        <slot name="left"></slot>
      </div>
      <div class="right-operate">
        <slot name="right"></slot>
      </div>
    </div>
    <a-table
      v-if="isFinish"
      @resizeColumn="handleResizeColumn"
      size="small"
      ref="tableRef"
      :pagination="false"
      :rowKey="rowKey"
      :dataSource="dataSourceRef"
      :height="height"
      :max-height="maxHeight"
      :bordered="border"
      :row-selection="
        showSelect ? (selectFunc ? selectFunc : rowSelection) : null
      "
      :scroll="scroll"
      :loading="loading"
      :defaultExpandAllRows="defaultExpandAllRows"
      :defaultExpandedRowKeys="defaultExpandedRowKeys"
    >
      <!--序列号-->
      <a-table-column
        v-if="getProps.showIndex"
        type="index"
        title="序号"
        width="70px"
        :fixed="indexFixed"
      >
        <template #default="{ index }">
          {{ index + 1 }}
        </template>
      </a-table-column>

      <!--表格内容-->
      
      <template v-for="item in (canColumnResize?newTableViewColumns:tableViewColumns)" :key="item">
        <template v-if="item.children">
          <a-table-column-group class="columnGroup">
            <template #title
              ><span>{{ item.title }}</span></template
            >
            <template v-for="_item in item.children" :key="_item">
              <a-table-column
                v-if="_item.type === 'text'"
                :ellipsis="_item.ellipsis?_item.ellipsis:true"
                :title="_item.title"
                :key="_item.key"
                :width="_item.width"
                :minWidth="_item.minwidth"
                :rowClassName="_item.className"
                :fixed="_item.fixed"
                :align="_item.align?_item.align:'center'"
                :resizable="_item.resizable"
              >
                <template #default="{ text }">
                  {{
                    item.formatter
                      ? item.formatter(text[item.key], text)
                      : text[item.key]
                  }}
                </template>
              </a-table-column>
              <a-table-column
                v-if="_item.type === 'customize'"
                :ellipsis="_item.ellipsis?_item.ellipsis:true"
                :title="_item.title"
                :key="_item.key"
                :width="_item.width"
                :minWidth="_item.minwidth"
                :rowClassName="_item.className"
                :fixed="_item.fixed"
                :align="_item.align?_item.align:'center'"
                :resizable="_item.resizable"
              >
                <template #default="{ record, index }">
                  <slot
                    :name="_item.slotName"
                    :prop="_item.prop"
                    :row="record"
                    :index="index"
                  />
                </template>
              </a-table-column>
            </template>
          </a-table-column-group>
        </template>
        <template v-else>
          <a-table-column
            v-if="item.type === 'text'"
            :ellipsis="item.ellipsis?item.ellipsis:true"
            :title="item.title"
            :key="item.key"
            :width="item.width"
            :minWidth="item.minwidth"
            :rowClassName="item.className"
            :fixed="item.fixed"
            :align="item.align?item.align:'center'"
            :resizable="item.resizable"
          >
            <template #default="{ text }">
              {{
                item.formatter
                  ? item.formatter(text[item.key], text)
                  : text[item.key]
              }}
            </template>
          </a-table-column>
          <a-table-column
            v-if="item.type === 'customize'"
            :ellipsis="item.ellipsis?item.ellipsis:true"
            :title="item.title"
            :key="item.key"
            :width="item.width"
            :minWidth="item.minwidth"
            :rowClassName="item.className"
            :fixed="item.fixed"
            :align="item.align?item.align:'center'"
            :resizable="item.resizable"
          >
            <template #default="{ record, index }">
              <slot
                :name="item.slotName"
                :prop="item.prop"
                :row="record"
                :index="index"
              />
            </template>
          </a-table-column>
        </template>
      </template>

      <!--操作列-->
      <a-table-column
        v-if="action != null"
        :title="action.title"
        :width="action.width"
        :fixed="action.fixed"
        :align="action.align?action.align:'center'"
      >
        <template #default="{ record, index }">
          <div>
            <template v-for="(item, _index) in action.items" :key="_index">
              <span
                class="actionClass"
                :style="item.style ? item.style : { color: '#57ABFF' }"
                v-if="item.visibleFn ? item.visibleFn(record, index) : true"
                :disabled="item.disabledFn && item.disabledFn(record, index)"
                :type="item.type || primary"
                text
                size="small"
                @click="item.onClick && item.onClick(record, index)"
              >
                <template v-if="item.type === 'text'">
                  {{ item.label }}
                </template>
                <template v-if="item.type === 'icon'">
                  <template v-if="item.iconTip">
                    <a-tooltip placement="top">
                      <template #title>
                        <span>{{ item.iconTip }}</span>
                      </template>
                      <component
                        :is="$antIcons[item.icon]"
                        :style="{ color: item.iconColor || '#0256FF' }"
                      />
                    </a-tooltip>
                  </template>
                  <template v-else>
                    <component
                      :is="$antIcons[item.icon]"
                      :style="{ color: item.iconColor || '#0256FF' }"
                    />
                  </template>
                </template>
              </span>
            </template>
          </div>
        </template>
      </a-table-column>
    </a-table>
  </div>
  <!--分页-->
  <a-pagination
    :class="{ atBottom: isPageBottom }"
    style="text-align: right"
    v-if="pagination"
    show-size-changer
    :page-size-options="pageSizeOptions"
    v-model:current="paginationProps.current"
    v-model:page-size="paginationProps.pageSize"
    :total="paginationProps.total"
    :style="{ marginTop: '20px' }"
    :show-total="(total) => `共${total}项`"
    @change="onPageChange"
    @showSizeChange="onPageSizeChange"
  >
  </a-pagination>
</template>
<script setup>
import {
  defineProps,
  reactive,
  ref,
  toRefs,
  computed,
  unref,
  provide,
  defineExpose,
  defineEmits,
  watch
} from "vue";
import { apiColWidthSet } from "@/api/common.js";
import { useTableColumns } from "./hooks/useTableColumns";
import { useTableData } from "./hooks/useTableData";

const props = defineProps({
  tableData: {
    //！！！table数据！！
    type: Array,
    default() {
      return [];
    },
  },
  columns: {
    //列
    type: Array,
    default() {
      return [];
    },
  },
  action: {
    //操作列
    type: Object,
    default() {
      return null;
    },
  },
  rowKey: {
    type: String,
    default: "id",
  },
  canColumnResize:{
    type: Boolean,
    default: false,
  },
  pagination: {
    //是否分页
    type: Boolean,
    default: false,
  },
  showSelect: Boolean, //列表项是否可选择
  selectFunc: Function,
  border: Boolean, //是否展示外边框和列边框
  showIndex: Boolean, //显示序号
  indexFixed: Boolean, //序号是否固定
  height: Number,
  maxHeight: Number,
  remote: Boolean, //是否自动调用接口获取数据
  remoteApi: {
    type: Function,
    default() {
      return () => {}; //Pormise,自动调用接口获取数据的接口函数
    },
  },
  queryParams: {
    //接口的其他参数
    type: Object,
    default() {
      return {};
    },
  },
  isPageBottom: Boolean, //分页是否展示在最底部
  immediate: Boolean, //是否一进页面就拉数据
  scroll: {
    type: Object,
    default() {
      return {};
    },
  },
  loading: Boolean,
  defaultExpandAllRows: Boolean,
  defaultExpandedRowKeys:{
    type: Array,
    default(){
      return []
    }
  },
  isFinish: {
    type: Boolean,
    default: true,
  },
});

const pageSizeOptions = ref(["10", "20", "50", "100"]);

//-selected
const selectedData = reactive({
  keys: [],
  rows: [],
});
const { keys: selectedRowKeys } = toRefs(selectedData);
const rowSelection = {
  selectedRowKeys: selectedRowKeys,
  onChange: (selectedRowKeys, selectedRows) => {
    selectedData.keys = selectedRowKeys;
    selectedData.rows = selectedRows;
  },
  getCheckboxProps: (record) => {
    return {
      disabled: record.sex == 4,
    };
  },
};
const clearSelected = () => {
  selectedData.keys = [];
  selectedData.rows = [];
};
//--end


//对外事件
const emits = defineEmits([
  "checkChange",
  "page-change",
  "pagesize-change",
  "search",
  "reset",
  "search-cancel",
  "row-click",
  "remote-api-success",
]);

const wrapRef = ref();
const tableRef = ref();
const tableHeaderRef = ref();
const innerPropsRef = ref();
const getProps = computed(() => {
  return { ...props, ...unref(innerPropsRef) };
});
const setProps = (props) => {
  innerPropsRef.value = { ...unref(innerPropsRef), ...props };
};

//伸缩列
const currentCol = ref({});
const currentWidth = ref({});
const handleResizeColumn = (w, col)=>{
  currentCol.value = col;
  currentWidth.value = w;
  document.addEventListener('mouseup',handleSaveColWidth)
  if(!props.canColumnResize)return;
  let findOne = newTableViewColumns.value.find(item=>item.title == col.title)
  findOne.width = w
  col.width = w;
}

const handleSaveColWidth = async()=>{   
    setTimeout(()=>{
      document.removeEventListener('mouseup',handleSaveColWidth)
    },100)
    apiColWidthSet({
      categoryBizId: newTableViewColumns.value[0].categoryBizId,
      prop: currentCol.value.key,
      width: currentWidth.value
    })
  }

//bug1--修复 无法监听 columns变化
const { tableViewColumns } = useTableColumns(props);
//伸缩列 特别处理
const newTableViewColumns = ref([]);
watch(()=>tableViewColumns.value,(newVal)=>{
  newTableViewColumns.value = newVal
})

const {
  paginationProps,
  dataSourceRef,
  onPageChange,
  onPageSizeChange,
  reload,
  refresh,
} = useTableData(getProps, emits);

const key = Symbol("basic-instance");
provide(key, {
  wrapRef: wrapRef,
  propsRef: getProps,
  setProps,
});

//高级搜索

//
defineExpose({
  reload,
  refresh,
  paginationProps,
  selectedData,
  clearSelected,
  dataSourceRef,
});
</script>
<style lang="less" scoped>
.basic-table-header {
  display: flex;
  justify-content: space-between;
}

.actionClass {
  cursor: pointer;
  margin-right: 10px;
}

.atBottom {
  position: absolute;
  bottom: 25px;
  right: 28px;
}

:deep(.columnGroup) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.06) !important;

  &::before {
    position: absolute;
    top: 50%;
    left: 0;
    width: 1px;
    height: 1.6em;
    background-color: rgba(0, 0, 0, 0.06);
    transform: translateY(-50%);
    transition: background-color 0.3s;
    content: "";
  }
}

.limitWorld {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
