import { nextTick, onMounted, reactive, ref, unref, watch } from 'vue';

export function useTableData(tableProps, emit){

    const paginationProps = reactive({

        pageSize: 10,
        pageSizeOptions: [10, 20, 50, 100],
        current: 1,
        total: 0
    })

    const {remote, remoteApi, pagination}  = unref(tableProps)

    //表格数据
    const dataSourceRef = ref([])

    //表格多选
   // const tableSelection = ref([])


    watch(
        ()=>unref(tableProps.tableData),
        ()=>{
            const { tableData, remote } = unref(tableProps)
            !remote && tableData && (dataSourceRef.value = tableData)
        }
    )

    //获取表格数据
    async function fetchTableData(){

        if(!remote || !remoteApi)return;
        let pageParams = {};
        if(pagination){
            pageParams['pageNo'] = paginationProps.current
            pageParams['pageSize'] = paginationProps.pageSize
            tableProps.value.queryParams 
                ?(pageParams = {...pageParams, ...tableProps.value.queryParams})
                :undefined
        }else{
            pageParams = tableProps.value.queryParams
        }
        const result = await remoteApi(pageParams)
        if(pagination){
            paginationProps.total = result.result.total
            dataSourceRef.value = result.result.records
        }else{
            dataSourceRef.value = result.result.records //记得修改
        }
        emit('remote-api-success', dataSourceRef.value)
    }

    /**
     * 刷新表格数据
     */
    function refresh(){
        fetchTableData()
    }

    /**
     * 表格数据重新加载
     */
    function reload(){
        paginationProps.current = 1
        nextTick(()=>{
            fetchTableData()
        })
    }

    /**
     * 分页大小切换
     */
    function onPageSizeChange(pageSize){
        emit('pagesize-change', pageSize)
    }

     /**
     * 分页页码切换
     */
     function onPageChange(pageNum){
        emit('page-change', pageNum)
        fetchTableData()
    }

     /**
     * 获取分页信息
     */
     function getPagination(){

        return unref(paginationProps)
    }

    onMounted(()=>{
        unref(tableProps).immediate && fetchTableData()
    })
    return {
        dataSourceRef,
        paginationProps,
        onPageSizeChange,
        onPageChange,
        reload,
        getPagination,
        refresh 
    }


}