import _ from "lodash";
import { ref, unref, watch, computed } from "vue";

export function useTableColumns(tableProps) {
  const columnsRef = ref(unref(tableProps).columns);

  watch(
    () => tableProps.columns,
    (newVal) => {
      columnsRef.value = newVal || [];
    },
    {
      deep: true,
    }
  );

  const tableViewColumns = computed(() => {
    const columns = _.cloneDeep(unref(columnsRef));
    if (!columns) return [];
    return columns.filter((column) => {
      return !column.hidden;
    });
  });

  const setColumns = (columnList) => {
    const columns = _.cloneDeep(columnList);
    columnsRef.value = columns;
  };

  return {
    setColumns,
    tableViewColumns,
  };
}
